<template>
  <a-spin :spinning="spinning">
    <div class="my-task" :style="backCss">
      <div class="left" v-if="taskList.length" style="display: flex;flex-direction: column;">
        <a-icon
          @click="prev"
          :style="isDesktop() ? 'font-size:30px' : 'font-size:15px'"
          v-if="taskList.findIndex(item => item.taskId == taskDetail.taskId)"
          type="left-circle"
          theme="filled"
        />
        <span
          :style="isDesktop() ? 'font-size:25px' : 'font-size:12px'"
          v-if="taskList.findIndex(item => item.taskId == taskDetail.taskId)"
          >{{ taskDetail.chapterSequenceNo - 1 }}</span
        >
      </div>
      <div class="right" v-if="taskList.length" style="display: flex;flex-direction: column;">
        <a-icon
          @click="next"
          :style="isDesktop() ? 'font-size:30px' : 'font-size:15px'"
          v-if="taskList.findIndex(item => item.taskId == taskDetail.taskId) != taskList.length - 1"
          type="right-circle"
          theme="filled"
        />
        <span :style="isDesktop() ? 'font-size:25px' : 'font-size:12px'">{{
          taskDetail.chapterSequenceNo - 0 + 1
        }}</span>
      </div>
      <a-row>
        <a-breadcrumb separator=">">
          <a-breadcrumb-item style="font-size:16px">
            <span style="cursor: pointer;" @click="skip">{{
              params.isMyTask ? '我的任务' : taskDetail.productionName || taskDetail.foreignName
            }}</span>
          </a-breadcrumb-item>
          <a-breadcrumb-item href="" style="font-size:16px">
            任务详情{{ taskDetail && taskDetail.chapterSequenceNo ? ' - ' + taskDetail.chapterSequenceNo : '' }}
          </a-breadcrumb-item>
        </a-breadcrumb>
      </a-row>
      <a-row v-if="taskDetail.productionName">
        <!-- 给稿：上传原稿
        制作：下载制作稿，上传完成稿
        交稿：下载终稿
        翻译图标按钮显示阶段：给稿、翻译、润色、校对、制作、监制、终审  陈立说的-->
        <a-button-group style="position: absolute;top: 9px;right: 108px;z-index:1;display: flex;">
          <a-tooltip v-if="taskDetail.stageName == '给稿'">
            <template slot="title">
              上传原稿
            </template>
            <a-button @click="gotoDetail(taskDetail)">
              <a-icon type="upload" />
            </a-button>
          </a-tooltip>
          <!-- 上传完成稿必须制作或者制作修改阶段与阶段里打开查稿系统开关同时满足才显示 陈立说的 -->
          <a-tooltip
            v-if="
              taskDetail.applyReview &&
                (taskDetail.stageName == '制作' ||
                  taskDetail.stageName == '制作修改' ||
                  taskDetail.stageName == '监制' ||
                  taskDetail.stageName == '监制确认' ||
                  taskDetail.stageName.indexOf('建模') > -1 ||
                  taskDetail.stageName.indexOf('精草') > -1 ||
                  taskDetail.stageName.indexOf('勾线') > -1 ||
                  taskDetail.stageName.indexOf('上色') > -1 ||
                  taskDetail.stageName.indexOf('后期') > -1)
            "
          >
            <template slot="title">
              上传完成稿
            </template>
            <a-button @click="gotoSumbmit(taskDetail)">
              <a-icon type="upload" />
            </a-button>
          </a-tooltip>
          <!--           <a-tooltip v-if="taskDetail.stageName == '交稿'">
            <template slot="title">
              下载终稿
            </template>
            <a-button>
              <a-icon type="download" />
            </a-button>
          </a-tooltip> -->
          <a-dropdown
            :disabled="spinning"
            v-if="
              (taskDetail.stageName == '监制确认' ||
                taskDetail.stageName == '监制' ||
                taskDetail.stageName == '交稿' ||
                taskDetail.stageName == '勾线终审' ||
                taskDetail.stageName == '上色终审') &&
                taskDetail.applyReview
            "
          >
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="downloadOver(false)">下载终稿 </a-menu-item>
              <a-menu-item key="2" @click="downloadOver(true)" v-if="taskDetail.departName.indexOf('日语部') > -1"
                >下载切片
              </a-menu-item>
            </a-menu>
            <a-button> <a-icon type="download" /> </a-button>
          </a-dropdown>
          <a-dropdown :disabled="spinning" v-if="taskDetail.stageName == '交稿' && taskDetail.applyTranslation">
            <a-menu slot="overlay">
              <a-menu-item key="1" @click="downloadJpg()">下载翻译JPG </a-menu-item>
              <a-menu-item key="2" @click="downloadPdf()" v-if="taskDetail.departName.indexOf('日语部') > -1"
                >下载翻译PDF
              </a-menu-item>
            </a-menu>
            <a-button> <a-icon type="download" /> </a-button>
          </a-dropdown>
          <a-tooltip
            v-else-if="
              taskDetail.stageName == '制作' && taskDetail.systemTypes.some(item => item == 'IMAGE_TRANSLATION_SYSTEM')
            "
          >
            <template slot="title">
              下载制作稿
            </template>
            <a-button @click="downloadMake" :loading="btnLoading">
              <a-icon type="download" />
            </a-button>
          </a-tooltip>
          <a-dropdown
            transitionName=""
            v-if="
              ((taskDetail.taskStatus != '等待' && taskDetail.taskStatus != '驳回') ||
                (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId)) &&
                taskDetail.applyReview
            "
            :trigger="['click']"
          >
            <a-button @mouseover="checkColor = color" @mouseout="checkColor = 'rgba(0, 0, 0, 0.45)'">
              <checklist
                style="position:relative;top:2px;left:3px"
                theme="outline"
                size="21"
                :fill="checkColor"
                :strokeWidth="3"
              />
            </a-button>
            <a-menu slot="overlay">
              <a-menu-item key="0" @click="goToFatchStraw(true)">
                加载锁定文件
              </a-menu-item>
              <a-menu-item key="1" @click="goToFatchStraw(false)">
                不加载锁定文件
              </a-menu-item>
            </a-menu>
          </a-dropdown>
          <a-button
            @click="goToTranslate"
            v-if="
              ((taskDetail.taskStatus != '等待' && taskDetail.taskStatus != '驳回') ||
                (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId)) &&
                taskDetail.applyTranslation
            "
          >
            <!--               ((taskDetail.stageName == '翻译' ||
                taskDetail.stageName == '润色' ||
                taskDetail.stageName == '校对' ||
                taskDetail.stageName == '制作' ||
                taskDetail.stageName == '监制' ||
                taskDetail.stageName == '终审' ||
                taskDetail.stageName == '给稿') &&
                taskDetail.taskStatus != '等待' &&
                taskDetail.taskStatus != '驳回') ||
                (taskDetail.taskStatus == '驳回' && taskDetail.rejectTaskId) -->

            <span class="iconfont icon-fanyi icon" style="color:rgba(0,0,0,0.65);font-size:18px"></span>
          </a-button>
        </a-button-group>
        <actions-tasks-btn
          style="position: absolute;top: 9px;right: 0px;z-index:1"
          :isMytaskNew="true"
          :propsParams="taskDetail"
          :loadData="reloadData"
          :taskList="taskDetail.taskList"
        ></actions-tasks-btn>
      </a-row>
      <a-row>
        <a-col :span="isDesktop() ? 10 : 24" style="padding-top:5vh">
          <a-row
            ><div
              class="task_info"
              :style="
                isDesktop()
                  ? 'height:70vh;font-size:14px;overflow-y: overlay;padding-top: 4px;'
                  : 'font-size:14px;padding-top: 4px;'
              "
            >
              <g-title style="position: relative;">基本信息</g-title>
              <a-button
                type="primary"
                @click="handleClickFormEdit"
                icon="edit"
                v-if="checkPermission('production:task:button:edit')"
                style="position: absolute;top:16px;right:3vw;"
                >{{ this.isEdit ? '退出编辑' : '编辑' }}</a-button
              >
              <div v-if="!isEdit">
                <div class="task-info-item">
                  <div>平台名称</div>
                  <div class="task-info-content">{{ taskDetail.platformName || '' }}</div>
                </div>
                <div class="task-info-item">
                  <div>作品名称</div>
                  <div class="task-info-content">{{ taskDetail.productionName || '' }}</div>
                </div>
                <div class="task-info-item">
                  <div>章节序号</div>
                  <div class="task-info-content">
                    {{ taskDetail.chapterSequenceNo }}
                  </div>
                </div>
                <div class="task-info-item">
                  <div>章节标题</div>
                  <div class="task-info-content">{{ taskDetail.chapterName }}</div>
                </div>
                <div class="task-info-item">
                  <div>结算数量</div>
                  <div class="task-info-content">
                    {{ getCalcUnits(taskDetail.calcUnits) }}
                  </div>
                </div>
                <div class="task-info-item">
                  <div>当前阶段</div>
                  <div class="task-info-content">{{ taskDetail.stageName }}</div>
                </div>
                <div class="task-info-item">
                  <div>当前人员</div>
                  <div class="task-info-content">
                    <img
                      :style="
                        isDesktop()
                          ? 'width:30px;height:30px;border-radius:50%;margin-right:10px;'
                          : 'width:24px;height:24px;border-radius:50%;margin-right:10px;'
                      "
                      :src="taskDetail.user ? taskDetail.user.avatar : ''"
                      alt=""
                    />
                    {{ taskDetail.user ? taskDetail.user.userName : '' }}
                  </div>
                </div>
                <div class="task-info-item">
                  <div>截止日期</div>
                  <div class="task-info-content">{{ deadline }}</div>
                </div>
                <div class="task-info-item">
                  <div>结算月份</div>
                  <div class="task-info-content">{{ settlementDate }}</div>
                </div>
                <div class="task-info-item">
                  <div>时间间隔</div>
                  <div class="task-info-content">{{ taskDetail.timeInterval }}</div>
                </div>
                <div class="task-info-item">
                  <div>完成情况</div>
                  <div class="task-info-content">
                    {{
                      taskDetail.finishStatus == 'ON_TIME'
                        ? '按时'
                        : taskDetail.finishStatus == 'ADVANCE'
                        ? '提前'
                        : taskDetail.finishStatus == 'DELAY'
                        ? '拖稿'
                        : ''
                    }}
                  </div>
                </div>
              </div>
              <a-form-model v-else :model="taskDetail" ref="taskForm">
                <a-form-model-item label="平台名称" style="display:flex">
                  <a-select disabled placeholder="请选择平台" v-model="taskDetail.platformId">
                    <a-select-option :value="item.platformId" v-for="item in platformList" :key="item.platformId">{{
                      item.platformName
                    }}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="作品名称" style="display:flex">
                  <a-input disabled v-model="taskDetail.productionName" />
                </a-form-model-item>
                <a-form-model-item label="章节序号" style="display:flex">
                  <a-input disabled v-model="taskDetail.chapterSequenceNo" />
                </a-form-model-item>
                <a-form-model-item label="章节标题" style="display:flex">
                  <a-input @blur="saveForm" v-model="taskDetail.chapterName" />
                </a-form-model-item>
                <a-form-model-item label="结算数量" style="display:flex">
                  <!--                   <div style="display:flex;justify-content: space-between;align-items: center;">
                    <div style="display:flex;" v-for="(c, index) in taskDetail.calcUnits" :key="index">
                      <a-input-number @blur="saveForm" :min="0" v-model="c.value"></a-input-number
                      ><span style="padding-left:5px;padding-right:5px">{{ getUnit(c.type) }}</span>
                    </div>
                  </div> -->
                  <div
                    v-if="taskDetail.calcUnits"
                    style="display:flex;justify-content: space-between;align-items: center;"
                  >
                    <div
                      style="display:flex;"
                      v-for="(c, index) in taskDetail.calcUnits.filter(c => {
                        return calcUnits.findIndex(i => i.type == c.type) > -1
                      })"
                      :key="index"
                    >
                      <a-input-number disabled @blur="saveForm" :min="0" v-model="c.value"></a-input-number
                      ><span style="padding-left:5px;padding-right:5px">{{ getUnit(c.type) }}</span>
                    </div>
                    <div
                      style="display:flex;"
                      v-for="(c, index) in calcUnits.filter(c => {
                        return taskDetail.calcUnits.findIndex(i => i.type == c.type) == -1
                      })"
                      :key="index"
                    >
                      <a-input-number disabled @blur="saveForm" :min="0" v-model="c.value"></a-input-number
                      ><span style="padding-left:5px;padding-right:5px">{{ getUnit(c.type) }}</span>
                    </div>
                  </div>
                </a-form-model-item>
                <a-form-model-item label="当前阶段" style="display:flex">
                  <a-input disabled v-model="taskDetail.stageName" />
                </a-form-model-item>
                <a-form-model-item label="当前人员" style="display:flex">
                  <a-select
                    @change="saveForm"
                    placeholder="请选择人员"
                    :disabled="!isTongChou && !isAdmin"
                    option-filter-prop="label"
                    v-model="taskDetail.user.userId"
                  >
                    <a-select-option
                      :value="item.userId"
                      :label="item.userName"
                      v-for="item in getSelectUser(allUsers, taskDetail)"
                      :key="item.userId"
                    >
                      <img
                        style="width:30px;height:30px;border-radius:50%;margin-right:10px;"
                        :src="item.avatar"
                        alt=""
                      />
                      <span>{{ item.userName || '' }}</span></a-select-option
                    >
                  </a-select>
                </a-form-model-item>
                <a-form-model-item label="截止日期" style="display:flex">
                  <a-date-picker
                    format="YYYY-MM-DD HH:mm"
                    @change="saveForm"
                    valueFormat="YYYY-MM-DD HH:mm"
                    :disabled="!isTongChou && !isAdmin"
                    :show-time="{ format: 'HH:mm', defaultValue: moment('23:59', 'HH:mm') }"
                    placeholder="请选择截止日期"
                    v-model="taskDetail.deadline"
                  />
                </a-form-model-item>
                <a-form-model-item label="结算月份" style="display:flex">
                  <a-month-picker
                    disabled
                    @change="saveForm"
                    format="YYYY-MM"
                    :show-time="{ format: 'YYYY-MM' }"
                    valueFormat="YYYY-MM"
                    v-model="taskDetail.settlementDate"
                    placeholder="请选择结算月"
                  />
                </a-form-model-item>
                <a-form-model-item label="时间间隔" style="display:flex">
                  <a-input disabled v-model="taskDetail.timeInterval" />
                </a-form-model-item>
                <a-form-model-item label="完成情况" style="display:flex">
                  <a-input
                    disabled
                    :value="
                      taskDetail.finishStatus == 'ON_TIME'
                        ? '按时'
                        : taskDetail.finishStatus == 'ADVANCE'
                        ? '提前'
                        : taskDetail.finishStatus == 'DELAY'
                        ? '拖稿'
                        : ''
                    "
                  />
                </a-form-model-item>
              </a-form-model>
              <g-title v-if="isDesktop()">历史记录</g-title>
              <a-radio-group v-model="radioValue">
                <a-radio :value="1">
                  任务历史
                </a-radio>
                <a-radio :value="2">
                  数据操作历史
                </a-radio>
              </a-radio-group>
              <a-timeline v-if="isDesktop() && radioValue == 1" style="margin-top:30px">
                <a-timeline-item style="font-size:14px" v-for="(item, index) in logList" :key="index"
                  >({{ item.operationTime }}){{ ' ' }} {{ item.userName + ': ' + item.content }}</a-timeline-item
                >
              </a-timeline>
              <a-timeline v-if="isDesktop() && radioValue == 2" style="margin-top:30px">
                <a-timeline-item style="font-size:14px" v-for="(item, index) in logListTwo" :key="index"
                  >({{ item.operationTime }}){{ ' ' }} {{ item.userName + ': ' + item.content }}</a-timeline-item
                >
              </a-timeline>
            </div>
          </a-row>
          <!--           <a-row>
            <div style="height:34vh;font-size:14px;overflow-y: overlay;">
              
              <div style="height:800px"></div>
            </div>
          </a-row> -->
        </a-col>
        <a-col :span="isDesktop() ? 14 : 24" style="padding-top:5vh;">
          <div
            v-if="tabShow || kpiShow || moneyShow || otherKpiShow || remarkShow"
            style="height:70vh;font-size:14px;padding-left:2.2vw;"
          >
            <a-tabs v-model="tab" @change="callback">
              <!-- isCoefficientPar && tabShow && taskDetail.isLocal -->
              <a-tab-pane
                :disabled="$refs.kpi && $refs.kpi.kpiDis"
                v-if="isCoefficientPar && tabShow && taskDetail.isLocal"
                key="1"
                tab="系数参数"
              >
                <coefficientPar
                  ref="coefficientPar"
                  :systemTypes="systemTypes"
                  @setTabVis="setTabVis"
                  @setIsCoefficientPar="setIsCoefficientPar"
                  :params="params"
                  :taskDetail="taskDetail"
                />
              </a-tab-pane>
              <!-- tabShow && taskDetail.isLocal -->
              <a-tab-pane
                v-has="'mytask:detail:button:coefficientPar'"
                v-if="tabShow && taskDetail.isLocal"
                :disabled="tabDis || ($refs.kpi && $refs.kpi.kpiDis)"
                key="2"
                tab="系数一览"
              >
                <rule ref="rule" :taskDetail="taskDetail" />
              </a-tab-pane>
              <a-tab-pane
                v-if="kpiShow || moneyShow || otherKpiShow"
                :disabled="tabDis && taskDetail.isLocal"
                key="3"
                tab="考核结算"
              >
                <kpi
                  ref="kpi"
                  :taskDetail="taskDetail"
                  :systemTypes="systemTypes"
                  :params="params"
                  :formData="kpiFormData"
                  :moneyShow="moneyShow"
                  :kpiShow="kpiShow"
                  :rule="rule"
                />
              </a-tab-pane>
              <a-tab-pane key="4">
                <span slot="tab">
                  <a-badge :count="msgTotal">
                    <span style="font-size:0.9vw">备注</span>
                  </a-badge>
                </span>
                <remark ref="remark" @getRemarkList="getRemarkList" :taskDetail="taskDetail" :params="params" />
              </a-tab-pane>
            </a-tabs>
          </div>
        </a-col>
      </a-row>
    </div>
  </a-spin>
</template>

<script>
import { getAction, postAction, v1postAction } from '@/api/manage'
import coefficientPar from './coefficientPar'
import { checkPermission } from '@/utils/hasPermission'
import { simpleDebounce } from '@/utils/util'
import { mixinDevice } from '@/utils/mixin.js'
import rule from './rule'
import kpi from './kpi'
import remark from './remark'
import { axios } from '@/utils/request'
import Vue from 'vue'
import { USER_INFO, ACCESS_TOKEN } from '@/store/mutation-types'
import moment from 'moment'
import actionsTasksBtn from '@/views/system/modules/mytasks/MyTaskactionsTasksBtn'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { Checklist } from '@icon-park/vue'
export default {
  mixins: [mixinDevice],
  components: { coefficientPar, rule, kpi, actionsTasksBtn, remark, Checklist },
  data() {
    return {
      isAdmin: false,
      params: {
        ...this.$route.params,
        record: this.$route.query.record,
        isMyTask: false
      },
      taskDetail: {
        productionName: '',
        platformName: '',
        chapterSequenceNo: ''
      },
      tab: '1',
      systemTypes: [],
      checkColor: 'rgba(0, 0, 0, 0.55)',
      backUrl: '',
      spinning: false,
      logList: [],
      isEdit: false,
      isCoefficientPar: true,
      platformList: [],
      allUsers: [],
      deadline: '',
      settlementDate: '',
      tabShow: false,
      kpiShow: false,
      moneyShow: false,
      otherKpiShow: false,
      isTongChou: false,
      skipUrl: '',
      kpiFormData: {
        kpiValueStages: []
      },
      taskList: [],
      tabDis: true,
      rule: {},
      radioValue: 1,
      logListTwo: [],
      calcUnits: [],
      remarkShow: false,
      targetLang: '',
      msgTotal: 0,
      btnLoading: false,
      color: '#000000',
      downProgress: 0
    }
  },
  computed: {
    backCss() {
      let color = this.$store.state.app.color
      this.color = this.$store.state.app.color
      return {
        '--theme-color': color
      }
    }
  },
  watch: {
    radioValue() {
      if (this.radioValue == 1) {
        this.getTaskLog()
      } else {
        this.getTaskLogTwo()
      }
    }
  },
  created() {
    this.backUrl = '/production/manage/detail/' + this.params.productionId + '?tab=3'
    this.getTask()
  },
  mounted() {
    this.$bus.$on('getSkipUrl', url => {
      this.backUrl = url
    })
    this.params.isMyTask = this.$route.query.isMyTask && this.$route.query.isMyTask != 'false' ? true : false
  },
  beforeDestroy() {
    this.$bus.$off('getSkipUrl')
  },
  methods: {
    moment,
    downImg(fileList) {
      const zip = new JSZip()
      const cache = {}
      const promises = []
      fileList.forEach((item, i) => {
        const promise = this.getFile(item).then(data => {
          // 下载文件, 并存成blob对象
          const file_name = item.name // 获取文件名(因为重复的文件名只会下载一个，故需要加入下标 不同名)
          zip.file(file_name, data, { binary: true }) // 逐个添加文件
          cache[file_name] = data
        })
        promises.push(promise)
      })
      Promise.all(promises).then(() => {
        zip.generateAsync({ type: 'blob' }).then(content => {
          // 生成二进制流   然后保存文件（如果这个下载不了 也可以将下方这一行换成a标签下载逻辑）
          FileSaver.saveAs(content, '翻译JPG.zip') // 利用file-saver保存文件  自定义文件名
        })
      })
    },
    async downloadOver(isSection) {
      let arr = [this.taskDetail]
      let that = this
      let open = false
      arr.forEach(async item => {
        const token = Vue.ls.get(ACCESS_TOKEN)
        let total = 0
        let productOrder = item.productOrder + ''
        let chapterOrder = item.chapterOrder + ''
        if (productOrder.length == 1) {
          productOrder = '00' + productOrder
        } else if (productOrder.length == 2) {
          productOrder = '0' + productOrder
        }
        if (chapterOrder.length == 1) {
          chapterOrder = '00' + chapterOrder
        } else if (chapterOrder.length == 2) {
          chapterOrder = '0' + chapterOrder
        }
        const res = await axios.post(
          Vue.prototype.API_BASE_URL +
            '/finish/manuscript/download?chapterId=' +
            this.taskDetail.chapterId +
            '&isSection=' +
            isSection,
          {},
          {
            headers: { 'X-Access-Token': token },
            responseType: 'blob',
            onDownloadProgress(progress) {
              // 监听下载进度的方法
              that.downProgress = Math.round((100 * progress.loaded) / progress.total)
              total = progress.loaded
              if (progress.total) {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad',
                    message: isSection
                      ? '下载切片-' + productOrder + '-' + item.productionName + '-' + chapterOrder
                      : '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: that.downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${that.downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    },
                    duration: that.downProgress == 100 ? 0.3 : null
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad',
                    message: isSection
                      ? '下载切片-' + productOrder + '-' + item.productionName + '-' + chapterOrder
                      : '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    description: h => {
                      return h('div', null, [
                        h(
                          'a-row',
                          {
                            props: {
                              type: 'flex'
                            },
                            attrs: {}
                          },
                          [
                            h(
                              'a-col',
                              {
                                props: {
                                  flex: 'auto'
                                }
                              },
                              [
                                h('a-progress', {
                                  props: {
                                    percent: that.downProgress,
                                    showInfo: false
                                  },
                                  attrs: {
                                    style: 'width:55%'
                                  }
                                }),
                                h(
                                  'span',
                                  {
                                    attrs: {
                                      style: 'color: rgba(0,0,0,.45);font-size: 1em;margin-left: 12px;'
                                    }
                                  },
                                  `${that.downProgress}%（${(progress.loaded / 1024 / 1024).toFixed(2)}/${(
                                    progress.total /
                                    1024 /
                                    1024
                                  ).toFixed(2)}M）`
                                )
                              ]
                            ),
                            h('a-col', {
                              props: {
                                flex: '38px'
                              },
                              attrs: {
                                style: 'margin-top:2px;'
                              }
                            })
                          ]
                        )
                      ])
                    },
                    duration: that.downProgress == 100 ? 0.3 : null
                  })
                }
              } else {
                if (!open) {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    duration: null,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M'
                  })
                } else {
                  that.$notification.open({
                    key: 'downLoad' + index,
                    message: '下载终稿-' + productOrder + '-' + item.productionName + '-' + chapterOrder,
                    description: '已下载' + (progress.loaded / 1024 / 1024).toFixed(2) + 'M',
                    duration: null
                  })
                }
              }
              open = true
              console.log(that.downProgress, progress.loaded, progress.total, 123)
            }
          }
        )
        if (!res) return
        const blob = res
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        let fileName = item.productionName + '-' + chapterOrder + '-终稿'
        if (this.taskDetail.departName.indexOf('原创部') == -1) {
          fileName = productOrder + '-' + chapterOrder
        }
        a.href = url
        a.download = fileName + '.zip'
        a.click()
        window.URL.revokeObjectURL(url)
      })
    },
    getFile(item) {
      let url = item.url
      return new Promise((resolve, reject) => {
        axios({
          method: 'get',
          url, // 原先后端下载单个文件的路径
          // responseType: "ArrayBuffer"  // 注意看下你自己的原先后端下载地址返回的文件格式 是blob 还是其他的啥 对应这里就要改成后端返回的下载格式
          responseType: 'blob'
        })
          .then(res => {
            resolve(res.data) // 返回文件的blob   Blob {size: 151382, type: 'image/png'}
          })
          .catch(error => {
            reject(error.toString())
          })
      })
    },
    downloadJpg() {
      localStorage.setItem('translate_download', 'JPG')
      /* window.open(`/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`) */
      const newUrl = `/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    downloadPdf() {
      localStorage.setItem('translate_download', 'PDF')
      /* window.open(`/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`) */
      const newUrl = `/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    async downloadMake() {
      let that = this
      this.btnLoading = true
      const rest = await v1postAction('/psd/get_chapter_make_psd_link', {
        productionId: this.taskDetail.productionId,
        chapterId: this.taskDetail.chapterId
      })
      if (rest.code == 200) {
        let postData = { list: [] }
        rest.data?.forEach(data => {
          let makeArr = []
          data.textAreas.forEach(textArea => {
            if (!textArea.characterSentences.length) return
            if (textArea.characterSentences[0] && textArea.characterSentences[0].characterText == ' ') {
              textArea.characterSentences = textArea.characterSentences.filter((t, tIdx) => tIdx)
              if (textArea.showText[0] == ' ') {
                textArea.showText = textArea.showText.replace(' ', '')
              }
            }
            let textArr = textArea.showText.split('\n')
            var reg = /^[a-zA-Z]+$/
            textArr = textArr.sort((a, b) => a.replaceAll('.', '').length - b.replaceAll('.', '').length)
            let textArrLength = 0
            textArr[textArr.length - 1].split('').forEach(text => {
              if (reg.test(text) || text == ' ') {
                textArrLength = textArrLength + 0.5
              } else if (text == '.') {
                textArrLength = textArrLength + 0.3
              } else {
                textArrLength++
              }
            })
            textArrLength = textArrLength.toFixed(0) - 0
            let maxWidth =
              (
                textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
              ).toFixed(0) - 0
            if (textArea.textLayout == 2) {
              maxWidth =
                (
                  textArr.length *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                  textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
                ).toFixed(0) - 0
            }
            let maxHeight =
              (
                textArr.length *
                textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize *
                textArea.characterSentences[textArea.characterSentences.length - 1].font.lineSpacing
              ).toFixed(0) - 0
            if (textArea.textLayout == 2) {
              maxHeight =
                (
                  textArrLength * textArea.characterSentences[textArea.characterSentences.length - 1].font.fontSize
                ).toFixed(0) - 0
            }
            let changeWidth = ((maxWidth - textArea.textAreaTranslationRegion.width) / 2).toFixed(0) - 0
            let changeHeight = ((maxHeight - textArea.textAreaTranslationRegion.height) / 2).toFixed(0) - 0
            let pushArr = []
            let brIndex = [-1]
            let newBrIndex = [-1]
            let changeBrIndex = []
            let phoneticArr = []
            let pushPhoneticText = ''
            let theX = 0
            let theY = 0
            let phoneticBrIndexOftext = []
            let arr = []
            textArea.showTexts = []
            textArea.characterSentences.forEach((text, textIndex) => {
              if (text.characterText == '\n') text.characterText = '<br>'
              if (text.characterText == '<br>') newBrIndex.push(textIndex)
              if (text.phonetic && text.phonetic.phoneticText) {
                phoneticBrIndexOftext.push(newBrIndex[newBrIndex.length - 1])
              }
              if (text.characterText != '<br>') {
                arr.push(text)
              } else {
                textArea.showTexts.push({ pId: '', texts: arr, textIndex })
                arr = []
                if (
                  textIndex == textArea.characterSentences.length - 1 &&
                  !arr.length &&
                  text.characterText == '<br>'
                ) {
                  textArea.showTexts.push({
                    pId: '',
                    texts: [
                      {
                        ...textArea.characterSentences[0],
                        characterSentenceId: 'new' + Math.round(Math.random() * 10000) + new Date().getTime(),
                        characterText: ' '
                      }
                    ],
                    pushTtextIndex: textIndex
                  })
                }
              }
              if (textIndex == textArea.characterSentences.length - 1 && arr.length) {
                textArea.showTexts.push({ pId: '', texts: arr, pushTtextIndex: textIndex })
              }
            })
            textArea.showTexts = textArea.showTexts.sort((a, b) => b.texts.length - a.texts.length)
            phoneticBrIndexOftext = Array.from(new Set(phoneticBrIndexOftext))
            let enNum = 0
            textArea.characterSentences.forEach((text, textIndex) => {
              let markArr = []
              if (text.characterText == '<br>') {
                brIndex.push(textIndex)
                enNum = 0
              }
              let minusWidth = 0
              /*               if (changeWidth < 0) {
                if (phoneticBrIndexOftext.length) {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                } else {
                  minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
                }
              } */
              minusWidth = ((textArr.length - 0.5) * text.font.fontSize).toFixed(0) - 0
              let textAlignWidth = 0
              if (text.font.textAlign == 'right' && textArea.showTexts[0]) {
                textAlignWidth = textArea.showTexts[0].texts.length * text.font.fontSize
              }
              theX =
                textArea.textLayout == 1
                  ? textArea.textAreaTranslationRegion.x - changeWidth + textAlignWidth
                  : textArea.textAreaTranslationRegion.x -
                    changeWidth +
                    phoneticBrIndexOftext.length * ((text.font.fontSize - 1) / 2 + 1) +
                    minusWidth
              theY =
                textArea.textLayout == 1
                  ? textArea.textAreaTranslationRegion.y - changeHeight + text.font.fontSize
                  : textArea.textAreaTranslationRegion.y - changeHeight + textAlignWidth
              let pushCharacterText = text.characterText == '<br>' ? '\r' : text.characterText
              if (
                textArea.characterSentences[textIndex - 1] &&
                textArea.characterSentences[textIndex - 1].characterText == '<br>' &&
                textArea.characterSentences[textIndex].characterText == ' '
              ) {
                pushCharacterText = ''
              }
              let textPushArr = [
                pushCharacterText == '\n' ? '\r' : pushCharacterText,
                (text.font.fontSize - 1) / 4 - 0,
                text.font.postScriptName,
                { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                text.font.textAlign,
                0.0,
                (text.font.lineSpacing * 100).toFixed(0) - 0,
                0.0,
                textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                theX,
                theY,
                text.font.strokeWidth || 0,
                text.font.strokeColor
                  ? {
                      red: text.font.strokeColor.red,
                      green: text.font.strokeColor.green,
                      blue: text.font.strokeColor.blue
                    }
                  : { red: 255, green: 255, blue: 255 },
                'antiAliasStrong',
                textArea.textLayout == 1 ? 0 : 1,
                0,
                text.characterSentenceId
              ]
              if (reg.test(text.characterText)) {
                if (
                  text.characterText == 'f' ||
                  text.characterText == 'i' ||
                  text.characterText == 'j' ||
                  text.characterText == 'l' ||
                  text.characterText == 't'
                ) {
                  enNum = enNum + 0.5
                } else {
                  if (text.characterText == 'A') {
                    enNum = enNum + 0.25
                  } /* else if(text.characterText=='B'){
                    enNum = enNum + 0.3
                  } */ else {
                    enNum = enNum + 0.3
                  }
                }
              }
              if (text.font.hasMark) {
                let markText = '.'
                let markX = 0
                if (!brIndex.length) {
                  markX = (textIndex - 1 - enNum) * text.font.fontSize + (text.font.fontSize / 2 - 0) - 3
                  if (textIndex > 8) {
                    markX = markX + ((textIndex - 8) / 3) * 2
                  }
                  if (text.font.isBold) {
                    markX = markX + textIndex
                  }
                } else {
                  markX =
                    (textIndex - brIndex[brIndex.length - 1] - 1 - enNum) * text.font.fontSize +
                    (text.font.fontSize / 2 - 0) -
                    3
                  if (textIndex - brIndex[brIndex.length - 1] > 8) {
                    markX = markX + ((textIndex - brIndex[brIndex.length - 1] - 8) / 3) * 2
                  }
                  if (text.font.isBold) {
                    markX = markX + (textIndex - brIndex[brIndex.length - 1])
                  }
                }
                if (text.font.textAlign == 'right') {
                  markX = theX - markX
                }
                let markPushArr = [
                  markText,
                  ((text.font.fontSize - 1) / 4).toFixed(0) - 0,
                  text.font.postScriptName,
                  { red: text.font.fontColor.red, green: text.font.fontColor.green, blue: text.font.fontColor.blue },
                  { bold: text.font.isBold ? true : false, italic: text.font.isIncline ? true : false },
                  text.font.textAlign,
                  0.0,
                  (text.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  textArea.textLayout == 1 ? text.font.textWidth * 100 : text.font.textHeight * 100,
                  text.font.textAlign == 'right' ? markX : theX + markX,
                  theY +
                    (brIndex.length - 2) +
                    (text.font.fontSize - 1) / 4 +
                    (((brIndex.length - 1) * text.font.fontSize * text.font.lineSpacing).toFixed(0) - 0),
                  text.font.strokeWidth || 0,
                  text.font.strokeColor
                    ? {
                        red: text.font.strokeColor.red,
                        green: text.font.strokeColor.green,
                        blue: text.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  0,
                  text.characterSentenceId
                ]
                markArr.push(markPushArr)
                makeArr.push(markArr)
              }
              pushArr.push(textPushArr)
              if (text.phonetic && text.phonetic.phoneticText) {
                if (text.phonetic.font) {
                  text.phonetic.font.postScriptName = text.font.postScriptName
                }
                let isFirstText = false
                if (!textIndex || textArea.characterSentences[textIndex - 1].characterText == '<br>') isFirstText = true
                phoneticArr.push({
                  targetCharacterIds: text.phonetic.targetCharacterIds,
                  brNum: brIndex.length - 1,
                  phonetic: text.phonetic,
                  text,
                  textIndex,
                  isFirstText
                })
              }
              if (
                phoneticArr.findIndex(p => p.textIndex == textIndex) > -1 &&
                phoneticArr.find(p => p.textIndex == textIndex).isFirstText &&
                phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText.length > 3
              ) {
                pushPhoneticText = ''
                let pushPhoneticTextLength = phoneticArr.find(p => p.textIndex == textIndex).phonetic.phoneticText
                  .length
                for (let i = 0; i < pushPhoneticTextLength - 3; i++) {
                  pushPhoneticText = pushPhoneticText + ' '
                }
              }
            })
            if (brIndex.length && phoneticArr.length) {
              brIndex.forEach((brIdx, brIdxIndex) => {
                brIdx = brIdx + 1 + brIdxIndex
                pushArr.splice(brIdx, 0, [
                  pushPhoneticText,
                  (phoneticArr[0].phonetic.font.fontSize - 1) / 4 - 0,
                  pushArr[0][2],
                  pushArr[0][3],
                  pushArr[0][4],
                  pushArr[0][5],
                  pushArr[0][6],
                  pushArr[0][7],
                  pushArr[0][8],
                  pushArr[0][9],
                  pushArr[0][10],
                  theX,
                  theY,
                  pushArr[0][13],
                  pushArr[0][14],
                  pushArr[0][15],
                  textArea.textLayout == 1 ? 0 : 1,
                  pushArr[0][17]
                ])
                changeBrIndex.push(brIdx - 1)
              })
            }
            if (phoneticArr.length) {
              let phoneticBrIndex = [...changeBrIndex]
              let pushPhoneticBrIndexArr = []
              let pushPhoneticBrNum = 0
              phoneticArr.forEach((p, pIdx) => {
                if (pushPhoneticBrIndexArr.indexOf(changeBrIndex[p.brNum]) > -1) {
                  return
                }
                pushArr.splice(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum, 0, [
                  '\r',
                  pushArr[0][1],
                  pushArr[0][2],
                  pushArr[0][3],
                  pushArr[0][4],
                  pushArr[0][5],
                  pushArr[0][6],
                  pushArr[0][7],
                  pushArr[0][8],
                  pushArr[0][9],
                  pushArr[0][10],
                  theX,
                  theY,
                  pushArr[0][13],
                  pushArr[0][14],
                  pushArr[0][15],
                  textArea.textLayout == 1 ? 0 : 1,
                  pushArr[0][17]
                ])
                /*                 changeBrIndex.forEach(c => {
                  if (
                    pushArr[c + pushPhoneticBrNum] &&
                    pushArr[c + pushPhoneticBrNum - 1] &&
                    pushArr[c + pushPhoneticBrNum][0] == '\r' &&
                    pushArr[c + pushPhoneticBrNum - 1][0] == '\r'
                  ) {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum - 1)
                  } else {
                    phoneticBrIndex.push(c == -1 ? 0 : c + pushPhoneticBrNum)
                  }
                }) */
                let pushBrNum = changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum
                phoneticBrIndex[p.brNum] = pushBrNum
                /* phoneticBrIndex.push(changeBrIndex[p.brNum] == -1 ? 0 : changeBrIndex[p.brNum] + pushPhoneticBrNum) */
                pushPhoneticBrIndexArr.push(changeBrIndex[p.brNum])
                pushPhoneticBrNum++
              })
              phoneticArr = phoneticArr.reverse()
              let phoneticSzie = 0
              let moveNum = 0
              brIndex = []
              pushArr.forEach((text, textIndex) => {
                if (
                  text[0] == '\r' &&
                  ((pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r') ||
                    (pushArr[textIndex + 1] && pushArr[textIndex + 1][0] != '\r') ||
                    !textIndex)
                ) {
                  /* if(pushArr[textIndex - 1] && pushArr[textIndex - 1][0] == '\r'&&pushArr[textIndex] && pushArr[textIndex][0] == '\r'){
                    brIndex.push(textIndex)
                  } */
                  brIndex.push(textIndex)
                }
              })
              phoneticArr.forEach((p, pIdx) => {
                if (!p.targetCharacterIds) p.targetCharacterIds = []
                let menuis = brIndex[0] == 0 ? 0 : 1
                moveNum = pushArr.findIndex(text => text[18] == p.targetCharacterIds[0]) - brIndex[p.brNum - menuis] - 1
                /* textArea.showText
                  .split('\n')
                  [p.brNum].split('')
                  .forEach((t, tEnIdx) => {
                    if (
                      reg.test(t) &&
                      textArea.characterSentences.findIndex(te => te.characterSentenceId == p.targetCharacterIds[0]) >
                        tEnIdx
                    ) {
                      moveNum--
                    }
                  }) */
                p.moveNum = moveNum
              })
              let addTotal = phoneticArr.length
              let addArr = []
              phoneticArr.forEach((p, pIdx) => {
                phoneticSzie = (p.phonetic.font.fontSize - 1) / 4 - 0
                let sortNum = phoneticArr
                  .filter(cp => cp.brNum == p.brNum)
                  .sort((a, b) => {
                    return a.moveNum - b.moveNum
                  })
                let addNum = 1
                if (p.phonetic.phoneticText.length > 1) {
                  addNum = 0
                } else if (p.phonetic.phoneticText.length == 1) {
                  addNum = 2
                }
                if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                  addNum = -2
                }
                console.log(p.moveWidth, p, sortNum, addNum)
                if (p.moveNum != sortNum[0].moveNum) {
                  if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) {
                    if (p.phonetic.phoneticText.length > 2) {
                      addNum = 0
                    } else if (p.phonetic.phoneticText.length == 2) {
                      addNum = 2
                    } else if (p.phonetic.phoneticText.length == 1) {
                      addNum = 3
                    }
                    if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                      addNum = addNum + 1
                    if (!p.phonetic.font.postScriptName) {
                      this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                    }
                  }
                  p.moveWidth =
                    (p.moveNum - sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].moveNum - 1) * 8 + addNum
                  let changeNum =
                    (sortNum[sortNum.findIndex(s => s.moveNum == p.moveNum) - 1].targetCharacterIds.length - 1) * 4
                  /*                     sortNum.forEach((s,sIdx)=>{
                      if(sIdx<sortNum.findIndex(s => s.moveNum == p.moveNum)){
                        changeNum=changeNum+(s.targetCharacterIds.length-1)*3
                      }
                    }) */
                  p.moveWidth = p.moveWidth - changeNum
                  if (p.phonetic.phoneticText.length > 5 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = p.moveWidth - 1
                  }
                  if (p.targetCharacterIds.length > 1) {
                    p.moveWidth = p.moveWidth + (p.targetCharacterIds.length - 1) * 4
                  }
                } else {
                  if (p.phonetic.font.postScriptName && p.phonetic.font.postScriptName.indexOf('851') > -1)
                    addNum = addNum + (p.moveNum == 1 ? 2 : 1)
                  if (!p.phonetic.font.postScriptName) {
                    this.$message.error('注音：' + p.phonetic.phoneticText + '未获取到字体名称')
                  }
                  if (/^[a-zA-Z]+$/.test(p.phonetic.phoneticText)) addNum = 1
                  p.moveWidth = (p.moveNum - 2 + p.targetCharacterIds.length) * 10 + addNum - 2
                  /* if (p.phonetic.phoneticText.length == 4 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = p.moveWidth - 2
                  } */
                  /*  if (p.phonetic.phoneticText.length == 3 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  }
                  if (p.phonetic.phoneticText.length == 2 && p.targetCharacterIds.length == 1) {
                    p.moveWidth = 1
                  } */
                  if (p.moveNum == 1) {
                    p.moveWidth = (p.targetCharacterIds.length - 1) * 4 + 2
                  }
                }
                let addText = ''
                for (let i = 0; i < p.moveWidth; i++) {
                  addText = addText + ' '
                }
                addArr.push({
                  index: phoneticBrIndex[p.brNum] + (phoneticBrIndex[p.brNum] ? addTotal : addTotal - 1),
                  addText,
                  text: p.text,
                  p
                })
                addTotal--
                pushArr.splice(phoneticBrIndex[p.brNum] ? phoneticBrIndex[p.brNum] + 1 : 0, 0, [
                  p.phonetic.phoneticText,
                  (p.phonetic.font.fontSize - 1) / 4 - 0,
                  p.phonetic.font.postScriptName,
                  {
                    red: p.text.font.fontColor.red,
                    green: p.text.font.fontColor.green,
                    blue: p.text.font.fontColor.blue
                  },
                  { bold: p.phonetic.font.isBold ? true : false, italic: p.phonetic.font.isIncline ? true : false },
                  'center',
                  p.phonetic.font.letterSpacing,
                  (p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  p.phonetic.font.textHeight * 100,
                  p.phonetic.font.textWidth * 100,
                  theX,
                  theY,
                  p.phonetic.font.strokeWidth || 0,
                  p.phonetic.font.strokeColor
                    ? {
                        red: p.phonetic.font.strokeColor.red,
                        green: p.phonetic.font.strokeColor.green,
                        blue: p.phonetic.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  1
                ])
              })
              addArr = addArr.reverse()
              addArr.forEach((a, aIdx) => {
                pushArr.splice(a.index + aIdx, 0, [
                  a.addText,
                  (a.p.phonetic.font.fontSize - 1) / 4 - 0,
                  'AdobeHeitiStd-Regular',
                  {
                    red: a.text.font.fontColor.red,
                    green: a.text.font.fontColor.green,
                    blue: a.text.font.fontColor.blue
                  },
                  { bold: a.p.phonetic.font.isBold ? true : false, italic: a.p.phonetic.font.isIncline ? true : false },
                  'center',
                  a.p.phonetic.font.letterSpacing,
                  (a.p.phonetic.font.lineSpacing * 100).toFixed(0) - 0,
                  0.0,
                  100,
                  100,
                  theX,
                  theY,
                  a.p.phonetic.font.strokeWidth || 0,
                  a.p.phonetic.font.strokeColor
                    ? {
                        red: a.p.phonetic.font.strokeColor.red,
                        green: a.p.phonetic.font.strokeColor.green,
                        blue: a.p.phonetic.font.strokeColor.blue
                      }
                    : { red: 255, green: 255, blue: 255 },
                  'antiAliasStrong',
                  textArea.textLayout == 1 ? 0 : 1,
                  1
                ])
              })
              if (pushPhoneticText.length) {
                pushArr.forEach(a => {
                  if (a[16] == 1) {
                    a[11] = a[11] + pushPhoneticText.length * (phoneticSzie / 2)
                  } else {
                    a[12] = a[12] - pushPhoneticText.length * (phoneticSzie / 2)
                  }
                })
              }
            }
            console.log(pushArr) //pushPhoneticBrIndexArr有问题
            debugger
            makeArr.push(pushArr)
          })

          postData.list.push(makeArr)
        })
        console.log(postData, 123)
        const res = await v1postAction('/psd/test', {
          ...postData,
          productionId: this.taskDetail.productionId,
          chapterId: this.taskDetail.chapterId
        })
        if (res.code == 1) {
          let name = this.taskDetail.productName + '-' + this.taskDetail.chapterOrder + '-制作稿.zip'
          let xhr = new XMLHttpRequest()
          // GET请求,link,async(是否异步)
          xhr.open('GET', '/api/v1' + res.data, true)
          xhr.setRequestHeader('X-Access-Token', Vue.ls.get(ACCESS_TOKEN))
          xhr.responseType = 'blob'
          xhr.onload = function(e) {
            if (this.status == 200) {
              let blob = this.response
              let a = document.createElement('a')
              // blob.type = "application/octet-stream";
              //创键临时link对象
              let url = window.URL.createObjectURL(new Blob([blob]))
              a.href = url
              a.download = name
              a.click()
              // 释放之前创建的URL对象
              window.URL.revokeObjectURL(res.data)
              a.remove()
              that.btnLoading = false
            } else {
              that.btnLoading = false
            }
          }
          // //发送请求
          xhr.send()
        }
      } else {
        that.btnLoading = false
        that.$message.error(res.msg)
      }
    },
    gotoDetail(record) {
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${record.productName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传原稿`
      })
    },
    gotoSumbmit(record) {
      this.$router.push({
        path: `/translate/mytasks/upload/${record.taskId}/${record.productionId}/${
          record.chapterId
        }?&record=${encodeURIComponent(
          JSON.stringify({ platformId: record.platformId, productionName: record.productName })
        )}&name=${record.productName + '-' + record.chapterOrder + '-' + record.stageName}&type=上传完成稿`
      })
    },
    goToFatchStraw(loadLock) {
      /* window.open(
        `/fatchStraw/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0` + (loadLock ? '?loadLock=true' : '')
      ) */
      const newUrl =
        `/fatchStraw/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0` + (loadLock ? '?loadLock=true' : '')
      const a = document.createElement('a')
      a.href = newUrl
      a.target = '_blank'
      a.click()
      document.removeChild(a)
    },
    goToTranslate() {
      /*       this.$router.push({
        path: `/translate/editor/${this.taskDetail.taskId}/${this.taskDetail.productionId}/${this.taskDetail.chapterId}/${this.taskDetail.platformId}`
      }) */
      console.log(window.performance.memory)
      localStorage.setItem('translate_record', JSON.stringify(this.taskDetail))
      /* window.open(
        `/translate/editor/${this.taskDetail.taskId}/${this.taskDetail.productionId}/${this.taskDetail.chapterId}/${this.taskDetail.platformId}?productionName=` +
          this.taskDetail.productionName+'&targetLang='+this.targetLang
      ) */
      localStorage.setItem('translate_download', '')
      window.open(`/translate/${this.taskDetail.taskId}/${this.taskDetail.chapterId}/0/0`)
    },
    getDetail() {
      getAction('/production/get', {
        id: this.params.productionId
      }).then(({ data }) => {
        this.calcUnits = data.calcUnits
        this.systemTypes = data.systemTypes || []
        this.targetLang = data.targetLang
        let userInfo = Vue.ls.get(USER_INFO)
        if (userInfo.roles.some(item => item.roleCode == 'boss' || item.roleCode == 'admin' || item.roleCode == 'hr')) {
          this.isTongChou = true
        } else if (userInfo.roles.some(item => item.roleCode == 'tongchou')) {
          if (data.adminUsers[0].userId == userInfo.id) {
            this.isTongChou = true
          } else {
            this.isTongChou = false
          }
        }
      })
    },
    getUnit(type) {
      let unit = ''
      switch (type) {
        case 'EACH':
          unit = '个'
          break
        case 'CHAPTER':
          unit = '话'
          break
        case 'VOLUME':
          unit = '部'
          break
        case 'PAGE':
          unit = '页'
          break
        case 'WORD':
          unit = '字'
          break
        case 'THOUSAND':
          unit = '千字'
          break
        case 'GRID':
          unit = '格'
          break
        case 'PERCENT':
          unit = '%'
          break
      }
      return unit
    },
    getCalcUnits(calcUnits) {
      let text = []
      calcUnits?.forEach(item => {
        if (item.value) {
          let unit = ''
          switch (item.type) {
            case 'EACH':
              unit = item.value + '个'
              break
            case 'CHAPTER':
              unit = item.value + '话'
              break
            case 'VOLUME':
              unit = item.value + '部'
              break
            case 'PAGE':
              unit = item.value + '页'
              break
            case 'WORD':
              unit = item.value + '字'
              break
            case 'THOUSAND':
              unit = item.value + '千字'
              break
            case 'GRID':
              unit = item.value + '格'
              break
            case 'PERCENT':
              unit = item.value + '%'
              break
          }
          text.push(unit)
        }
      })
      text = text.join('')
      return text
    },
    setTabVis() {
      this.tabDis = false
    },
    async next() {
      this.tabDis = true
      if (this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) == this.taskList.length - 1) return
      this.params.taskId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 0 + 1
      ].taskId
      this.params.chapterId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 0 + 1
      ].chapterId
      const data = this.taskList[this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 0 + 1]
      this.$router.push({
        path: `/translate/mytasks/detail/${data.taskId}/${data.productionId}/${
          data.chapterId
        }?record=${encodeURIComponent(
          JSON.stringify({
            platformId: this.taskDetail.platformId
          })
        )}&isMyTask=${false}&name=${this.taskDetail.productionName + '-' + data.chapterOrder + '-' + data.taskName}`
      })
      this.$bus.$emit(
        'nextTaskPage',
        `${this.taskDetail.productionName + '-' + data.chapterOrder + '-' + data.taskName}`
      )
      /*       this.callback('1')
      this.tab = '1'
      this.getAlldata()
      if (this.$refs.coefficientPar) this.$refs.coefficientPar.getFormData() */
    },
    async prev() {
      this.tabDis = true
      if (!this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId)) return
      this.params.taskId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 1
      ].taskId
      this.params.chapterId = this.taskList[
        this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 1
      ].chapterId
      const data = this.taskList[this.taskList.findIndex(item => item.taskId == this.taskDetail.taskId) - 1]
      this.$router.push({
        path: `/translate/mytasks/detail/${data.taskId}/${data.productionId}/${
          data.chapterId
        }?record=${encodeURIComponent(
          JSON.stringify({
            platformId: this.taskDetail.platformId
          })
        )}&isMyTask=${false}&name=${this.taskDetail.productionName + '-' + data.chapterOrder + '-' + data.taskName}`
      })
      this.$bus.$emit(
        'prevTaskPage',
        `${this.taskDetail.productionName + '-' + data.chapterOrder + '-' + data.taskName}`
      )
      /*       this.callback('1')
      this.tab = '1'
      this.getAlldata()
      if (this.$refs.coefficientPar) this.$refs.coefficientPar.getFormData() */
    },
    async getRemarkList() {
      const res = await getAction(
        '/production/chapter/note/list?chapterId=' + this.params.chapterId + '&stageId=' + this.taskDetail.stageId
      )
      if (res.code == 200) {
        if (res.data) {
          this.msgTotal = res.data.length
        } else {
          this.msgTotal = 0
        }
      }
    },
    getAlldata: simpleDebounce(async function() {
      this.getTaskLog()
      this.getAllPlatform()
      this.getAllUsers()
      this.getRuleList()
    }, 2000),
    async getTaskList() {
      const res = await getAction('/tasks/taskChapters', { taskId: this.taskDetail.taskId })
      try {
        if (res.code == 200) {
          this.taskList = res.data
        } else {
          this.message.error(res.message)
        }
      } catch (err) {
        this.$message.error(err)
      }
    },
    reloadData() {
      this.getTask(true)
      if (this.$refs.coefficientPar) {
        this.tab = '1'
        this.$refs.coefficientPar.getFormData()
      }
    },
    skip() {
      if (this.isCoefficientPar && this.tabShow && this.taskDetail.isLocal) {
        let newData = JSON.stringify(this.$refs.coefficientPar.formData)
        let oldData = JSON.stringify(this.$refs.coefficientPar.oldData)
        let self = this
        if (oldData != newData) {
          this.$confirm({
            title: '提示',
            content: '您尚未保存数据，需要保存吗？',
            onOk() {
              self.$refs.coefficientPar.editProduction()
            },
            onCancel() {
              /* self.$router.push(self.backUrl) */
              if (self.params.isMyTask) {
                /* window.history.go(-1) */
                self.$router.push('/system/mytasks')
              } else {
                self.$router.push(
                  '/production/manage/detail/' +
                    self.taskDetail.productionId +
                    '?name=' +
                    self.taskDetail.productionName
                )
              }

              /* self.$refs.coefficientPar.getFormData()
              self.tab=tab
              self.$refs.coefficientPar.isEditProduction=false */
            }
          })
        } else {
          /* self.$router.push(self.backUrl) */
          if (self.params.isMyTask) {
            /*  window.history.go(-1) */
            self.$router.push('/system/mytasks')
          } else {
            self.$router.push(
              '/production/manage/detail/' + self.taskDetail.productionId + '?name=' + self.taskDetail.productionName
            )
          }
          if (this.$refs.coefficientPar) this.$refs.coefficientPar.isEditProduction = false
        }
      } else {
        if (this.params.isMyTask) {
          this.$router.push('/system/mytasks')
        } else {
          this.$router.push(
            '/production/manage/detail/' + this.taskDetail.productionId + '?name=' + this.taskDetail.productionName
          )
        }
      }
    },
    checkPermission(type) {
      return checkPermission(type)
    },
    setIsCoefficientPar() {
      this.isCoefficientPar = false
      this.tab = '2'
    },
    getSelectUser(users, item) {
      let arr = []
      users.forEach(user => {
        for (let key in user.positions) {
          if (
            item.stageList.some(stage => {
              return stage.positionName == user.positions[key].positionName && stage.stageName == item.stageName
            })
          ) {
            arr.push(user)
            break
          }
        }
      })
      users = arr
      return users
    },
    //节流
    async getRuleList() {
      const res = await getAction('production/get_production_stage', { id: this.params.productionId, size: '-1' })
      try {
        res.data?.forEach(stage => {
          if (
            stage.stageName == this.taskDetail.stageName &&
            stage.ruleList?.some(rule => {
              return rule.ruleType == 'DIFFICULTLY'
            })
          ) {
            this.tabShow = true
          }
          if (
            stage.stageName == this.taskDetail.stageName &&
            stage.ruleList?.some(rule => {
              return rule.ruleType == 'KPI'
            })
          ) {
            this.kpiShow = true
          }
          if (
            stage.stageName == this.taskDetail.stageName &&
            stage.ruleList?.some(rule => {
              return rule.ruleType == 'MONEY'
            })
          ) {
            this.moneyShow = true
            this.rule = stage.ruleList.find(rule => rule.ruleType == 'MONEY')
          }
        })
        if (this.tabShow) this.tab = '1'
        if (!this.tabShow) this.tab = '2'
        if (!this.tabShow) this.tab = '3'
        /*         if (!this.taskDetail.isLocal) {
          this.tab = '3'
        } */
        if (!this.taskDetail.isLocal) {
          this.tab = '3'
        }
        if (!this.tabShow && !this.kpiShow && !this.moneyShow && !this.otherKpiShow) {
          this.remarkShow = true
          this.tab = '4'
        }
        this.tabDis = false
        const { productionId, chapterId } = this.params
        const beenKpiTaskId = this.taskDetail.id
        const beenKpiStageId = this.taskDetail.currentStage.id
        let draftStageId = ''
        let deliveryStageId = ''
        this.taskDetail.stageList.forEach(item => {
          if (item.stageName == '给稿') draftStageId = item.stageId
          if (item.stageName == '交稿') deliveryStageId = item.stageId
        })
        const rest = await getAction('/factorView/othersKpiView', {
          kpiToTaskId: beenKpiTaskId,
          kpiToStageId: beenKpiStageId,
          chapterId,
          productId: productionId,
          draftStageId,
          deliveryStageId
        })
        const kpiRes = await getAction('/factorView/kpiView', {
          beenKpiTaskId,
          beenKpiStageId,
          chapterId,
          productId: productionId,
          draftStageId,
          deliveryStageId
        })
        this.kpiFormData = kpiRes.data || { kpiValueStages: [] }
        try {
          if (rest.code == 200) {
            rest.data.forEach(item => {
              item.kpiValues.forEach(kpi => {
                kpi.isEdit = false
              })
            })
            if (rest.data.length) {
              this.otherKpiShow = true
            }
            if (this.$refs.kpi) {
              this.$refs.kpi.kpiList = rest.data
              if (!this.kpiShow) {
                this.$refs.kpi.viewType = 2
              }
            }
          }
          this.spinning = false
        } catch (err) {}
        this.spinning = false
      } catch (err) {
        this.spinning = false
      }
    },
    getAllUsers() {
      postAction('/sys/user/queryUser', { departmentIds: JSON.parse(this.params.record).departmentIds }).then(res => {
        this.allUsers = Object.assign([], res.data)
      })
    },
    getAllPlatform() {
      return getAction('/platform/list?pageSize=1000').then(res => {
        if (res.success) {
          this.platformList = res.data.records
        }
      })
    },
    async saveForm() {
      const res = await postAction('/tasks/update', {
        calcUnits: this.taskDetail.calcUnits,
        user: this.taskDetail.user,
        ownerId: this.taskDetail.user.userId,
        deadline: this.taskDetail.deadline.length <= 16 ? this.taskDetail.deadline + ':00' : this.taskDetail.deadline,
        settlementDate:
          this.taskDetail.settlementDate.length < 9
            ? this.taskDetail.settlementDate + '-01'
            : this.taskDetail.settlementDate,
        platformId: this.taskDetail.platformId,
        productionId: this.taskDetail.productionId,
        taskId: this.taskDetail.taskId,
        chapterName: this.taskDetail.chapterName
      })
      if (res.code == 200) {
        this.$message.success('更新成功')
        this.getTaskLog()
        this.getTaskLogTwo()
      } else {
        this.$message.console.error(res.msg || res.message)
      }
    },
    handleClickFormEdit() {
      if (this.isEdit) {
        this.getTask()
      }
      this.isEdit = !this.isEdit
    },
    async getTask(type) {
      this.spinning = true
      getAction('tasks/get/' + this.params.taskId).then(res => {
        if (res.code == 200) {
          this.taskDetail = res.data
          if (!this.taskDetail.isLocal) {
            this.tab = '3'
            this.tabDis = false
          }
          this.deadline = JSON.parse(JSON.stringify(this.taskDetail)).deadline
          this.settlementDate = JSON.parse(JSON.stringify(this.taskDetail)).settlementDate || ''
          if (this.deadline.length > 16) {
            this.deadline = this.deadline.substr(0, this.deadline.length - 1)
            this.deadline = this.deadline.substr(0, this.deadline.length - 1)
            this.deadline = this.deadline.substr(0, this.deadline.length - 1)
          }
          if (this.settlementDate && this.settlementDate.length > 7) {
            this.settlementDate = this.settlementDate.substr(0, this.settlementDate.length - 1)
            this.settlementDate = this.settlementDate.substr(0, this.settlementDate.length - 1)
            this.settlementDate = this.settlementDate.substr(0, this.settlementDate.length - 1)
          }
          this.getAlldata()
          this.getDetail()
          let userInfo = Vue.ls.get(USER_INFO)
          this.isAdmin = userInfo.roles.some(item => {
            return item.roleCode == 'admin' || item.roleCode == 'boss'
          })
          this.getTaskList()
          this.spinning = false
          this.getRemarkList()
        } else {
          this.spinning = false
          this.$message.error(res.msg)
        }
      })
    },
    async getTaskLog() {
      const res = await getAction(`/tasks/list_log/${this.params.taskId}`)
      try {
        this.logList = res.data
      } catch (err) {
        console.log(err)
        this.spinning = false
      }
    },
    async getTaskLogTwo() {
      const res = await getAction(`/log/list?type=KPI_VALUE&objectId=${this.params.taskId}`)
      try {
        this.logListTwo = res.data
      } catch (err) {
        console.log(err)
        this.spinning = false
      }
    },
    callback(tab) {
      console.log(this.tab)
      if (this.tab != '1') {
        if (this.$refs.coefficientPar) {
          let newData = JSON.stringify(this.$refs.coefficientPar.formData)
          let oldData = JSON.stringify(this.$refs.coefficientPar.oldData)
          let self = this
          if (oldData != newData) {
            this.tab = '1'
            this.$confirm({
              title: '提示',
              content: '您尚未保存数据，需要保存吗？',
              onOk() {
                self.$refs.coefficientPar.editProduction()
              },
              onCancel() {
                self.$refs.coefficientPar.isEditProduction = false
                self.tab = tab
                if (self.tab == '2') {
                  self.$nextTick(() => {
                    self.$refs.rule.getFormData()
                  })
                } else if (self.tab == '3') {
                  self.$nextTick(() => {
                    self.$refs.kpi.getFormData()
                  })
                }
                /* self.$refs.coefficientPar.getFormData()
              self.tab=tab
              self.$refs.coefficientPar.isEditProduction=false */
              }
            })
          } else {
            if (this.$refs.coefficientPar) this.$refs.coefficientPar.isEditProduction = false
            if (this.tab == '2') {
              this.$nextTick(() => {
                this.$refs.rule.getFormData()
              })
            } else if (this.tab == '3') {
              this.$nextTick(() => {
                this.$refs.kpi.getFormData()
                this.$refs.kpi.viewType = 1
              })
            }
          }
        } else {
          if (this.$refs.coefficientPar) this.$refs.coefficientPar.isEditProduction = false
          if (this.tab == '2') {
            this.$nextTick(() => {
              this.$refs.rule.getFormData()
            })
          } else if (this.tab == '3') {
            this.$nextTick(() => {
              this.$refs.kpi.getFormData()
              this.$refs.kpi.viewType = 1
            })
          }
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.ant-btn:hover {
  .icon-fanyi {
    color: var(--theme-color) !important;
    transition: all 0.6s;
  }
}
.left,
.right {
  position: absolute;
  width: 2vw;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  > span {
    font-weight: 700;
    color: rgba(0, 0, 0, 0.35);
    opacity: 0;
    cursor: default;
  }
  i {
    color: rgba(0, 0, 0, 0.1);
  }
  &:hover {
    i {
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
      transition: color 0.3s ease-in-out;
      &:hover {
        color: rgba(0, 0, 0, 0.25);
        ~ span {
          opacity: 1;
          transition: opacity 0.3s ease-in-out;
        }
      }
    }
  }
}
.left {
  left: 10px;
}
.right {
  right: 10px;
}
::-webkit-scrollbar {
  width: 0px;
}
.task_info {
  &:hover {
    &::-webkit-scrollbar {
      width: 12px;
    }
  }
}
/deep/ .ant-badge-count {
  top: -5px !important;
  right: -5px !important;
}
/deep/ .ant-form-item-control-wrapper {
  width: 10vw !important;
}
@media screen and (max-width: 768px) {
  /deep/ .ant-form-item-control-wrapper {
    width: 40vw !important;
  }
}
/deep/ .ant-form-item-label {
  width: 18% !important;
  label {
    font-size: 14px !important;
  }
}
/deep/ .left-bar {
  background-color: var(--theme-color) !important ;
}
.my-task {
  padding-top: 5vh;
  padding-bottom: 2vh;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  position: relative;
  overflow: hidden;
}
.border-bottom {
  border-bottom: none !important;
}
.text-lg {
  font-size: 0.9vw !important;
  font-weight: bold;
}
/deep/ .left-bar {
  margin-top: 2px !important;
}
/deep/ .ant-tabs-tab {
  font-size: 0.9vw !important;
}
.task-info-item {
  display: flex;
  justify-content: space-between;
  width: 50%;
  margin-bottom: 2vh;
  .task-info-content {
    width: 65%;
    text-align: left;
  }
}
.ant-select-selection__rendered {
  img {
    display: none;
  }
}
</style>
